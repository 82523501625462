import React, { useState, useEffect,useMemo } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';
import './OvertimeApproval.css';

const ApprovalFlow = ({ dateRange, approvalData, deptId, availableDepartments,lydoGroupId }) => {
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    const start = moment(dateRange.startDate, 'DD/MM/YYYY');
    const end = moment(dateRange.endDate, 'DD/MM/YYYY');
    const datesTabs = [];

    for (let m = moment(start); m.diff(end, 'days') <= 0; m.add(1, 'days')) {
      datesTabs.push(m.format('DD/MM/YYYY'));
    }

    setTabs(datesTabs);
  }, [dateRange]);
  
//   const filteredApprovalData = useMemo(() => {
//     if (!approvalData) return {};
    
//     return Object.fromEntries(
//       Object.entries(approvalData).map(([date, dateData]) => [
//         date,
//         {
//           ...dateData,
//           approvalFlows: Object.fromEntries(
//             Object.entries(dateData.approvalFlows).filter(([dept]) => 
//               deptId === '' || deptId === 'Tất cả' 
//                 ? availableDepartments.includes(dept)
//                 : dept === deptId
//             )
//           )
//         }
//       ])
//     );
//   }, [approvalData, deptId, availableDepartments]);
  const calculateTotalApprovedAndRegistered = (date) => {
    const dateData = approvalData[date];
    if (!dateData) return { totalApproved: 0, totalRegistered: 0 };

    let totalApproved = 0;
    let totalRegistered = 0;

    Object.entries(dateData.approvalFlows).forEach(([deptName, flow]) => {
      // Tìm cấp độ phê duyệt cuối cùng
      const finalLevel = flow.find(step => step.isFinal)?.level;
      
      // Chỉ tính số lượng phê duyệt từ cấp độ cuối cùng
      if (finalLevel) {
        totalApproved += dateData.approvalLogs[deptName]?.[finalLevel]?.totalApproved || 0;
      }
      // Tổng hợp tất cả số lượng đăng ký cho mỗi phòng ban
      totalRegistered += dateData.reasonsAndQuantities[deptName]?.count || 0;
    });
    
    return { totalApproved, totalRegistered };
  };

  const renderApprovalFlow = (date) => {
    const dateData = approvalData[date];
    if (!dateData) return null;

    return (
      <div className="approval-flows-container">
        {Object.entries(dateData.approvalFlows).map(([deptName, flow], index) => {
          // Find the final approval level
          const finalLevel = flow.find(step => step.isFinal)?.level;
          // Get the final approved count
          const finalApprovedCount = dateData.approvalLogs[deptName]?.[finalLevel] || 0;
          const reasons = dateData.reasonsAndQuantities[deptName]?.reasons || [];
          const filteredReasons = lydoGroupId 
            ? reasons.filter(reason => reason.lydoGroupId === lydoGroupId)
            : reasons;
          return (
            <div key={deptName} className="approval-flow-container mb-4">
              <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <div>
                    <h4 className="text-primary">Đề xuất của: {deptName.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</h4>
                    <p>Bộ phận: {filteredReasons.map(reason => reason.deptName).join(', ')}</p>
                  </div>
                  <div>
                    {filteredReasons.map((group, idx) => (
                      <React.Fragment key={idx}>
                        {idx > 0 } 
                        <div className="reason-item mb-2 reason-badges-container">
                          {group.reason && (
                          <>
                            <span className="badge rounded-pill text-bg-info m-2 mt-2">Lý do: {group.reason}</span>
                            {group.mahang && <span className="badge text-bg-secondary m-2 mt-2">Mã hàng: {group.mahang}</span>}
                            {group.khachhang && <span className="badge rounded-pill text-bg-success m-2 mt-2">Khách hàng: {group.khachhang}</span>}
                            {group.nhaan && <span className="badge rounded-pill text-bg-warning m-2 mt-2">Nhà ăn: {group.nhaan}</span>}
                            {group.maynenkhi && <span className="badge rounded-pill text-bg-danger m-2 mt-2">Máy nén khí: {group.maynenkhi}</span>}
                            {group.timeRanges && <span className="badge rounded-pill text-bg-dark m-2 mt-2" title="Thời gian đăng ký ăn ca">Thời gian: {group.timeRanges}</span>}
                            <hr className="reason-separator" style={{display:'block',width:'100%'}}/>
                            </>
                            
                          )}
                          
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
                <div className="card-body">
                  {/* {filteredReasons.map((group, idx) => (
                    <React.Fragment key={idx}>
                      {idx > 0 && <hr className="duration" />}
                      <div className="duration-item mb-2">
                        
                      </div>
                    </React.Fragment>
                  ))} */}

              <div className="approval-flow mt-4 mb-4 d-flex justify-content-between">
              {flow.map((step, index) => {
                const isCompleted = index < dateData.currentSteps[deptName];
                const isCurrent = index === dateData.currentSteps[deptName];
                const isFinal = step.isFinal;
                const approvalLogData = dateData.approvalLogs[deptName]?.[step.level] || {};
                const approvedCount = approvalLogData.totalApproved || 0;
                const totalRegistered = dateData.reasonsAndQuantities[deptName]?.count || 0;
                const createdAt = approvalLogData.createdAt;
                // Tính toán số lượng chờ duyệt
                let pendingCount = 0;
                  if (index === 0) {
                    pendingCount = totalRegistered - approvedCount;
                  } else {
                    const previousApprovalLogData = dateData.approvalLogs[deptName]?.[flow[index - 1].level] || {};
                    const previousApprovedCount = previousApprovalLogData.totalApproved || 0;
                    pendingCount = previousApprovedCount - approvedCount;
                  }

                  const showPendingCount = index <= dateData.currentSteps[deptName];
                // Thêm điều kiện để xác định trạng thái "đang xử lý"
                const isProcessing = approvedCount > 0 && pendingCount > 0;

                return (
                        <div 
                          key={index} 
                          className={`approval-step 
                            ${isCompleted ? 'completed' : ''} 
                            ${isCurrent ? 'current' : ''} 
                            ${isProcessing ? 'processing' : ''}`}
                        >
                          <div className="step-circle">
                            <div className="step-number">{step.level}</div>
                          </div>
                          <div className="step-content">
                            <div className="approver-name">{step.approverName}</div>
                            <div className="approved-count">Đã duyệt: {approvedCount}</div>
                            {showPendingCount && <div className="pending-count">Chờ duyệt: {pendingCount}</div>}
                            <small className="d-block text-muted fw-bolder">
                                {createdAt && (() => {
                                  const date = new Date(createdAt);
                                  const dateStr = date.toLocaleDateString('vi-VN', { day: '2-digit', month: '2-digit',year: 'numeric' }).replace('-','/');
                                  const timeStr = date.toLocaleTimeString('vi-VN', { hour: '2-digit', minute: '2-digit', hour12: false });
                                  return `${dateStr} ${timeStr} `;
                                })()}
                              </small>
                            {isFinal && <div className="final-step">{isCompleted ? 'Đã duyệt' : 'Đang đợi duyệt'}</div>}
                          </div>
                        </div>
                      );
                  
                })}
                
              </div>
              </div>
              </div>
              {index < Object.entries(dateData.approvalFlows).length - 1 && <hr className="approval-flow-separator" />}
            </div>
          );
        })}
      </div> 
    );
  };

  return (
    <Tabs className="nav-tabs-container"> 
      <TabList className="nav nav-tabs">
        {tabs.map((date) => {
          const { totalApproved, totalRegistered } = calculateTotalApprovedAndRegistered(date);
          return (
            <Tab key={date} className="nav-item">
              <span className="nav-link">
                {date}
                <br />
                <small style={{color:"#b52323"}}>Đăng ký: {totalRegistered}/ Tổng đã duyệt:{totalApproved}</small>
                {/* / Tổng đã duyệt:{totalApproved} */}
              </span>
            </Tab>
          );
        })}
      </TabList>

      {tabs.map((date) => (
        <TabPanel key={date} className="tab-content">
          <div className="tab-pane fade show active">
            {renderApprovalFlow(date)}
          </div>
        </TabPanel>
      ))}
    </Tabs>
  );
};

export default ApprovalFlow;