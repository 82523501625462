import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import '../assets/style.css';
import $ from 'jquery';
import 'daterangepicker/daterangepicker.css';
import 'daterangepicker';
import moment from 'moment-timezone';
import NavTabs from './NavTabs';
import PieChartComponent from './piechart';
import { API_URL } from '../authlogin/api';

function SummaryPage() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [editStatus, setEditStatus] = useState('');
  const [editNotes, setEditNotes] = useState('');
  const [startDate, setStartDate] = useState(moment().startOf('day').format('DD/MM/YYYY'));
  const [endDate, setEndDate] = useState(moment().endOf('day').format('DD/MM/YYYY'));
  const [filterStatus, setFilterStatus] = useState('');
  const [filterDepartment, setFilterDepartment] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const rowsPerPage = 20;
  const [showChartAndTable, setShowChartAndTable] = useState(false);
  const [departments, setDepartments] = useState([]);
  

   useEffect(() => {
    document.title = "Tổng hợp suất ăn";
    initializeDateRangePicker();
    fetchData();
  }, []);

  const initializeDateRangePicker = () => {
    $('input.range-date').daterangepicker({
      showDropdowns: true,
      minYear: 2024,
      autoApply: true,
      startDate: moment(startDate, 'DD/MM/YYYY'),
      endDate: moment(endDate, 'DD/MM/YYYY'),
      
      locale: {
        format: 'DD/MM/YYYY',
      }
    }, function(start, end, label) {
      setStartDate(start.format('DD/MM/YYYY'));
      setEndDate(end.format('DD/MM/YYYY'));
      fetchData(start.format('DD/MM/YYYY'), end.format('DD/MM/YYYY'));
    });
  };
  
  

  const fetchData = async (start = startDate, end = endDate) => {
    try {
      const response = await axios.get(`${API_URL}/employees`, {
        params: {
          startDate: moment(start, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          endDate: moment(end, 'DD/MM/YYYY').format('YYYY-MM-DD')
        }
      });
      
      setData(response.data);
        // Tạo danh sách bộ phận duy nhất
      const uniqueDepartments = [...new Set(response.data.map(employee => employee.phongban))];
      setDepartments(uniqueDepartments);
    } catch (error) {
      setError('Có lỗi xảy ra khi tải dữ liệu');
      
    }
  };

  const formatDateToVietnamese = (date) => {
    return new Intl.DateTimeFormat('vi-VN', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(date));
  };

  const formatDateToVietnameseWithTimezone = (date) => {
    return moment(date).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss');
  };

  const handleEdit = (index, employee) => {
    setEditIndex(index);
    setEditStatus(employee.trangthai);
    setEditNotes(employee.ghichusuatan);
  };

  const handleUpdate = async (id) => {
    try {
      await axios.put(`${API_URL}/employees/${id}`, {
        trangthai: editStatus,
        ghichusuatan: editNotes
      });
      alert('Cập nhật thông tin thành công');
      fetchData();
      setEditIndex(null);
    } catch (error) {
      console.error(error);
      alert('Cập nhật thông tin thất bại.');
    }
  };

 const handleDelete = async (id) => {
  const isConfirmed = window.confirm('Bạn có chắc chắn muốn huỷ dữ liệu này không?');

  if (isConfirmed) {
    const note = prompt('Vui lòng nhập ghi chú cho việc huỷ:');
    if (note === null) {
      alert('Hành động huỷ đã bị hủy bỏ.');
      return;
    }

    try {
      await axios.put(`${API_URL}/employees/${id}`, {
        trangthai: 'huỷ',
        ghichusuatan: note
      });
      alert('Cập nhật trạng thái thành công');
      fetchData();
    } catch (error) {
      console.error(error);
      alert('Cập nhật trạng thái thất bại.');
    }
  } else {
    alert('Hành động huỷ đã bị hủy bỏ.');
  }
};

  const exportToGoogleSheets = async () => {
    try {
      const response = await axios.post(`${API_URL}/api/export`, {
        startDate,
        endDate,
        filterStatus,
        filterDepartment,
      });
      
      if (response.status === 200) {
        alert('Xuất dữ liệu thành công');
      } else {
        alert('Xuất dữ liệu thất bại');
      }
    } catch (error) {
      console.error('Error exporting users to Google Sheets', error);
      alert('Error exporting users to Google Sheets');
    }
  };

  const handleFilterStatusChange = (e) => {
    setFilterStatus(e.target.value);
  };

  const handleFilterDepartmentChange = (e) => {
  setFilterDepartment(e.target.value);
};

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredUsers = useMemo(() => {
    
    return data.filter((employee) => {
      const userDate = moment(employee.ngaydk, 'YYYY-MM-DD').toDate();
      const Start = moment(startDate, 'DD/MM/YYYY').toDate();
      const End = moment(endDate, 'DD/MM/YYYY').toDate();
      let dateMatch = true;
      if (startDate && endDate) {
        dateMatch = userDate >= Start && userDate <= End;
      }
       else if (startDate) {
        dateMatch = userDate >= Start;
      } else if (endDate) {
        dateMatch = userDate <= End;
      }
      
      let statusMatch = true;
      if (filterStatus) {
        statusMatch = employee.trangthai === filterStatus;
      }
      
      let departmentMatch = true;
    if (filterDepartment) {
      departmentMatch = employee.phongban === filterDepartment;
    }

      let searchMatch = true;
      if (searchTerm) {
        const regex = new RegExp(searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
        searchMatch = regex.test(employee.MaNV) || regex.test(employee.TenNV) || regex.test(employee.bophan);
      }

      return dateMatch && statusMatch && departmentMatch && searchMatch;
    });
  }, [data, startDate, endDate, filterStatus, filterDepartment, searchTerm]);

  const dateCounts = useMemo(() => {
    const counts = {};
    filteredUsers.forEach((employee) => {
      const formattedDate = formatDateToVietnamese(employee.ngaydk);
      counts[formattedDate] = (counts[formattedDate] || 0) + 1;
    });
    return counts;
  }, [filteredUsers]);

  
  const statusCounts = useMemo(() => {
    const counts = {};
    filteredUsers.forEach((employee) => {
      const key = `${employee.trangthai} - ${employee.bophan}`;
      counts[key] = (counts[key] || 0) + 1;
    });
    return counts;
  }, [filteredUsers]);

  const totalDaAn = useMemo(() => {
    return filteredUsers.filter(employee => employee.trangthai === 'Đã ăn').length;
  }, [filteredUsers]);

  const totalChuaAn = useMemo(() => {
    return filteredUsers.filter(employee => employee.trangthai === 'Chưa ăn').length;
  }, [filteredUsers]);

  const totalsByDepartment = useMemo(() => {
    const totals = {};
    filteredUsers.forEach((employee) => {
      const department = employee.phongban;
      if (!totals[department]) {
        totals[department] = { 'Đã ăn': 0, 'Chưa ăn': 0 };
      }
      if (employee.trangthai === 'Đã ăn') {
        totals[department]['Đã ăn']++;
      } else if (employee.trangthai === 'Chưa ăn') {
        totals[department]['Chưa ăn']++;
      }
    });
    return totals;
  }, [filteredUsers]);

  const goToDataEntry = () => {
    window.open('https://docs.google.com/spreadsheets/d/1bVkZ_Wubzmx4xnQzQicyOOGntTQXBfblyaZFWpBTzpg/edit?hl=vi&gid=0#gid=0', '_blank');
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredUsers.slice(indexOfFirstRow, indexOfLastRow);

  const totalPages = Math.ceil(filteredUsers.length / rowsPerPage);

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const toggleChartAndTable = () => {
    setShowChartAndTable(!showChartAndTable);
  };

  return (
    <>
      <NavTabs />
      <div className="container bg-web pt-3">
        <form>
          <div className="mb-3 row">
            <h1 className="text-center">Trang tổng hợp</h1>
          </div>
          <div className="row">
            <label htmlFor="date" className="col-4 col-lg-2 col-form-label mb-4">Ngày</label>
            <div className="col-8 col-lg-4 mb-4">
              <input type="text" id="date" className="range-date form-control" value={`${startDate} - ${endDate}`} readOnly />
            </div>
            <label htmlFor="filter-status" className="col-4 col-lg-2 col-form-label mb-4">Trạng thái</label>
            <div className="col-8 col-lg-4 mb-4">
              <select id="filter-status" name="state" required="" className="form-select" value={filterStatus} onChange={handleFilterStatusChange}>
                <option value="">Tất cả</option>
                <option value="Đã ăn">Đã ăn</option>
                <option value="Chưa ăn">Chưa ăn</option>
                <option value="đăng ký">đăng ký</option>
              </select>
            </div>
            <label htmlFor="filter-department" className="col-4 col-lg-2 col-form-label mb-4">Phòng ban</label>
            <div className="col-8 col-lg-4 mb-4">
              <select id="filter-department" name="state" required="" className="form-select" value={filterDepartment} onChange={handleFilterDepartmentChange}>
                <option value="">Tất cả</option>
                {departments.map((department, index) => (
                  <option key={index} value={department}>{department}</option>
                ))}
              </select>
            </div>
            <label htmlFor="search-text" className="col-4 col-lg-2 col-form-label mb-4">Tìm kiếm</label>
            <div className="col-8 col-lg-4 mb-4">
              <input id="search-text" type="text" className="form-control" placeholder="Tìm kiếm số thẻ hoặc họ tên..." required="" value={searchTerm} onChange={handleSearchTermChange} />
            </div>
            <div className="col-lg-12 mb-4 text-center d-flex justify-content-center align-items-center">
              <button 
                type="button" 
                className="btn btn-success height-fill-available font-size-1-2 me-2" 
                style={{ width: 'calc(50% - 50px)' }} 
                onClick={exportToGoogleSheets}
              >
                Xuất dữ liệu
              </button>
              <button 
                onClick={goToDataEntry} 
                className="btn btn-secondary height-fill-available font-size-1-2"
                style={{ width: '100px' }}
              >
                Sheet
              </button>
            </div>
          </div>
        </form>
       <div className="row mb-4">
          <h2 className="text-center col-12">Tổng hợp số liệu đăng ký</h2>
          <div className="col-sm-6">
            Số liệu ngày: 
            {Object.entries(dateCounts).map(([date, count], index) => (
              <span key={date}>
                {date}: {count}
                {index !== Object.entries(dateCounts).length - 1 && ', '}
              </span>
            ))}
          </div>
          <div className="col-sm-6 text-center">
            <button className="w-50 btn btn-primary height-fill-available font-size-1-2" onClick={toggleChartAndTable}>
              {showChartAndTable ? 'Ẩn thống kê' : 'Hiện thống kê'}
            </button>
          </div>
        </div>

        {showChartAndTable && (
          <div className="row mb-4">
            <div className="col-sm-6">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Bộ phận</th>
                    <th>Đã ăn</th>
                    <th>Chưa ăn</th>
                    <th>Tổng</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(totalsByDepartment).map((department, index) => (
                    <tr key={index}>
                      <td>{department}</td>
                      <td>{totalsByDepartment[department]['Đã ăn']}</td>
                      <td>{totalsByDepartment[department]['Chưa ăn']}</td>
                      <td>{totalsByDepartment[department]['Đã ăn'] + totalsByDepartment[department]['Chưa ăn']}</td>
                    </tr>
                  ))}
                  <tr className="table-active">
                    <td>Tổng đăng ký nhà ăn</td>
                    <td>{totalDaAn}</td>
                    <td>{totalChuaAn}</td>
                    <td>{totalDaAn + totalChuaAn}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-sm-6 text-center">
            <div className="pie-chart">
              <PieChartComponent statusCounts={statusCounts} />
              </div>
              </div>
          </div>
        )}
        <div style={{ overflowX: 'auto' }} className="mb-4">
          <table className="table table-bordered table-responsive table-striped table-tonghop">
            <thead className="sticky-top">
              <tr>
                <th>Số thẻ</th>
                <th>Họ tên</th>
                <th>Bộ phận</th>
                <th>Ngày đăng ký</th>
                <th>Trạng thái</th>
                <th>Thời gian quét</th>
                <th>Ghi chú</th>
                <th>Thao tác</th>
              </tr>
            </thead>
            <tbody>
              {currentRows.map((row, index) => (
                <tr key={row._id}>
                  <td>{row.MaNV}</td>
                  <td>{row.TenNV}</td>
                  <td>{row.bophan}</td>
                     
                  <td>{formatDateToVietnamese(row.ngaydk)}</td>
                  <td>
                    {editIndex === index ? (
                      <select
                        value={editStatus}
                        onChange={(e) => setEditStatus(e.target.value)}
                        className="form-select"
                      >
                        <option value="Đã ăn">Đã ăn</option>
                        <option value="Chưa ăn">Chưa ăn</option>
                      </select>
                    ) : (
                      row.trangthai
                    )}
                  </td>
                  <td>
                    {row.trangthai === 'Đã ăn'
                      ? formatDateToVietnameseWithTimezone(row.thoigianquet)
                      : '---'}
                  </td>
                  <td>
                    {editIndex === index ? (
                      <input
                        type="text"
                        value={editNotes}
                        onChange={(e) => setEditNotes(e.target.value)}
                        className="form-control"
                      />
                    ) : (
                      row.ghichusuatan
                    )}
                  </td>
                  <td>
                    {editIndex === index ? (
                      <button className="btn btn-primary" onClick={() => handleUpdate(row._id)}>Lưu</button>
                    ) : (
                      <>
                        <button className="btn btn-primary" onClick={() => handleEdit(index, row)}>Cập nhật</button>
                        <button className="btn btn-danger" onClick={() => handleDelete(row._id)}>Xóa</button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handleClick(index + 1)}
              className={`btn ${index + 1 === currentPage ? 'btn-primary' : 'btn-secondary'}`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </>
  );
}

export default SummaryPage;