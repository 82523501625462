  import React, { useState, useEffect } from 'react';
  import axios from 'axios';
  import moment from 'moment';
  import { useNavigate } from 'react-router-dom';
  import DateRangePicker from 'react-bootstrap-daterangepicker';
  import 'bootstrap/dist/css/bootstrap.min.css';
  import 'bootstrap-daterangepicker/daterangepicker.css';
  import NavTabs from './NavTabs';
  import './OvertimeApproval.css';
  import ApprovalFlow from './workflow';
  import { API_URL } from '../authlogin/api';
  import { FaPrint } from "react-icons/fa";
  
  
function Workflowview() {
    const [dateRange, setDateRange] = useState({
      startDate: moment().format('DD/MM/YYYY'),
      endDate: moment().format('DD/MM/YYYY')
    });  
    // Khởi tạo state với giờ hiện tại  
    const [timeRange, setTimeRange] = useState({  
      startTime: '16:30',  
      endTime: '20:00' 
    });
    const [userInfo, setUserInfo] = useState(null);
    const navigate = useNavigate();
    const [currentCapDo, setCurrentCapDo] = useState('');
    const [approvalData, setApprovalData] = useState({});
    const [isLoadings, setIsLoadings] = useState(false);

    useEffect(() => {
      document.title = "Xem luồng duyệt";
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
      } else {
        fetchUserInfo(); 
        
      }
    }, [navigate]);
  
    useEffect(() => {
      if (userInfo && dateRange.startDate && dateRange.endDate) {
        
        fetchApprovalFlow();
        
        
      }
    }, [dateRange, currentCapDo, userInfo]);

    

    
     // Hàm để lấy thông tin luồng duyệt
     
     const fetchApprovalFlow = async () => {
    try {
      const response = await axios.get(`${API_URL}/approval-flow`, {
        params: {
          startDate: dateRange.startDate,
          endDate: dateRange.endDate
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setApprovalData(response.data);
    } catch (error) {
      console.error('Error fetching approval flow:', error);
    }
  };
  
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get(`${API_URL}/user-info`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        setUserInfo(response.data);
        setCurrentCapDo(response.data.capDo);
      } catch (error) { 
        console.error('Error fetching user info:', error);
      }
    };
    const handleDateChange = (event, picker) => {
      setDateRange({
        startDate: picker.startDate.format('DD/MM/YYYY'),
        endDate: picker.endDate.format('DD/MM/YYYY')
      });     
      
    };
   const handleExportCSV = async () => {
    try {
      const response = await axios.get(`${API_URL}/export-csv`, {
        params: {
          startDate: dateRange.startDate,
          endDate: dateRange.endDate  
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        responseType: 'blob' // Important: This tells axios to treat the response as binary data
      });

      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: 'text/csv' });

      // Create a link element and trigger the download
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `overtime_${dateRange.startDate}_to_${dateRange.endDate}.csv`;
      link.click();

      // Clean up
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Error exporting CSV:', error);
    }
  };
  const exportToExcelByDepartment = async () => {
  try {
    if (isLoadings) return;
    setIsLoadings(true);
    const startDate = dateRange.startDate;
    const endDate = dateRange.endDate;
    const url = `${API_URL}/export-excel-explanation?startDate=${startDate}&endDate=${endDate}`;

    // Tải nội dung HTML từ API
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const htmlContent = await response.text();

    // Tạo một iframe ẩn
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);

    // Ghi nội dung vào iframe
    iframe.contentDocument.write(htmlContent);
    iframe.contentDocument.close();

    // Thêm style cho in ấn
    const style = iframe.contentDocument.createElement('style');
    style.textContent = `
      @media print {
        body { -webkit-print-color-adjust: exact; }
        @page { size: landscape; }
      }
    `;
    iframe.contentDocument.head.appendChild(style);

    // Đợi cho iframe load xong
    iframe.onload = () => {
      try {
        iframe.contentWindow.print();
      } catch (error) {
        console.error('Error printing:', error);
        alert('Có lỗi xảy ra khi in. Vui lòng thử lại.');
      }
    };

    // Xóa iframe sau một khoảng thời gian
    setTimeout(() => {
      document.body.removeChild(iframe);
    }, 5000);

  } catch (error) {
    console.error('Error printing report:', error);
    alert('Có lỗi xảy ra khi tạo báo cáo in');
  } finally {
    setIsLoadings(false);
  }
};
  const exportToExcel = async () => {
    try {
      const response = await axios.get(`${API_URL}/export-overtimeHCQT`, {
        params: {
          startDate: dateRange.startDate,
          endDate: dateRange.endDate
        },
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Báo cáo danh sách ăn ca_${dateRange.startDate}-${dateRange.endDate}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error exporting to Excel:', error);
      alert('Có lỗi xảy ra khi xuất file Excel');
    }
  };
  // Hàm render để hiển thị luồng duyệt
  const renderApprovalFlow = () => {
    
    return (
      <ApprovalFlow
        dateRange={dateRange}
        approvalData={approvalData}
      />
    );
  };
    
    return (
    <div className="overtime-approval ">
      <NavTabs/>
      <h2 className="text-center my-3">Luồng duyệt danh sách ăn ca</h2>
      
      <div className="filter-controls">
        <div className="row g-3">
          <div className="col-12 col-md-6 col-lg-4">
            <label className="form-label">Chọn khoảng thời gian:</label>
            <DateRangePicker
              initialSettings={{
                startDate: dateRange.startDate,
                endDate: dateRange.endDate,
                maxDate: moment('DD/MM/YYYY'),
                locale: {
                  format: 'DD/MM/YYYY'
                }
              }}
              onApply={handleDateChange}
            >
              <input type="text" className="form-control" value={`${dateRange.startDate} - ${dateRange.endDate}`} readOnly />
            </DateRangePicker>
          </div>
        </div>
        {currentCapDo != '2' && (
        <div className="row g-3">
          <div className="col-12 col-md-6 col-md-4 col-lg-3">
            <button className="btn btn-success w-100" onClick={handleExportCSV}>
              Xuất file CSV import
            </button>
          </div>
          
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <button className="btn btn-success w-100" onClick={exportToExcel}>
              Xuất file excel đăng ký nhà ăn
            </button>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <button className="btn btn-primary w-100" onClick={exportToExcelByDepartment}>
              <FaPrint size={20}/> In file ký duyệt  
            </button>
          </div>
        </div>
        )}
      </div>
      <div className="table mt-3">     
        {renderApprovalFlow()}
      </div>
    </div>
  );
  }

  export default Workflowview;