import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Table, Nav, Tab, Card, Badge, Button } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './OvertimeGeneral.css';
import axios from 'axios';
import moment from 'moment';
import { FaCalendarAlt, FaUsers, FaClipboardCheck, FaUtensils, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { MdFastfood } from "react-icons/md";
import { API_URL } from '../authlogin/api';
import { useNavigate } from 'react-router-dom';
import NavTabs from './NavTabs';

const OvertimeGeneral = () => {
  const [dateRange, setDateRange] = useState({
    startDate: moment().startOf('day'),
    endDate: moment().endOf('week')
  });
  const [overtimeData, setOvertimeData] = useState({});
  const [activeDate, setActiveDate] = useState(moment().format('YYYY-MM-DD'));
  const navigate = useNavigate();
  useEffect(() => {
    fetchOvertimeData();
  }, [dateRange]);
  document.title='Tổng quan'
  const fetchOvertimeData = async () => {
    try {
      const token = localStorage.getItem('token'); // Get the token from localStorage
      if (!token) {
        // If there's no token, redirect to login page
        navigate('/login');
        return;
      }

      const response = await axios.get(`${API_URL}/api/overtime-general`, {
        params: {
          startDate: dateRange.startDate.format('YYYY-MM-DD'),
          endDate: dateRange.endDate.format('YYYY-MM-DD')
        },
        headers: {
          Authorization: `Bearer ${token}` 
        }
      });
      const groupedData = groupDataByDate(response.data);
      setOvertimeData(groupedData);
      setActiveDate(Object.keys(groupedData)[0] || moment().format('YYYY-MM-DD'));
    } catch (error) {
      console.error('Error fetching overtime data:', error);
      if (error.response && error.response.status === 401) {
        // If the error is 401, redirect to login page
        navigate('/login');
      }
    }
  };
  
  const handleDateRangeChange = (event, picker) => {
    setDateRange({
      startDate: picker.startDate,
      endDate: picker.endDate
    });
  };
  const groupDataByDate = (data) => {
  return data.reduce((acc, item) => {
    const date = moment(item.ngaydk).format('YYYY-MM-DD');
    if (!acc[date]) {
      acc[date] = {
        total: 0,
        phongBans: {},
      };
    }
    acc[date].total += 1;
    const phongBanName = item.phongBan.TenPhongBan;
    const boPhanName = item.boPhan.TenBoPhan;
    const nguoiDuyetName = item.nguoiDuyet ? item.nguoiDuyet.TenNV : 'Chưa có người duyệt';
    if (!acc[date].phongBans[phongBanName]) {
      acc[date].phongBans[phongBanName] = {
        dangKy: 0,
        choDuyet: 0,
        daDuyet: 0,
        daAn: 0,
        chuaAn: 0,
        choDuyetGroups: {}
      };
    }
    
    acc[date].phongBans[phongBanName].dangKy += 1;
    if (item.daduyet) {
      acc[date].phongBans[phongBanName].daDuyet += 1;
    } else {
      acc[date].phongBans[phongBanName].choDuyet += 1;
      // Cập nhật số lượng chờ duyệt cho bộ phận và người duyệt
      if (!acc[date].phongBans[phongBanName].choDuyetGroups[boPhanName]) {
        acc[date].phongBans[phongBanName].choDuyetGroups[boPhanName] = {};
      }
      if (!acc[date].phongBans[phongBanName].choDuyetGroups[boPhanName][nguoiDuyetName]) {
        acc[date].phongBans[phongBanName].choDuyetGroups[boPhanName][nguoiDuyetName] = 0;
      }
      acc[date].phongBans[phongBanName].choDuyetGroups[boPhanName][nguoiDuyetName] += 1;
    }
    acc[date].phongBans[phongBanName].daAn += item.trangthai === 'Đã ăn' ? 1 : 0;
    acc[date].phongBans[phongBanName].chuaAn += item.trangthai === 'Chưa ăn' ? 1 : 0;
    
    return acc;
  }, {});
};

const renderTable = (data) => {
   // Tạo chuỗi ghi chú
  const createGhiChu = (choDuyetGroups) => {
  return Object.entries(choDuyetGroups)
    .map(([boPhan, nguoiDuyets]) => {
      const nguoiDuyetStrings = Object.entries(nguoiDuyets)
        .map(([nguoiDuyet, count]) => 
          `<div style="font-weight:700;">Chờ duyệt <strong style="color: #d3480b;">${count}</strong>: ` +
          `<span style="font-weight: 600; color:#007bff;">${boPhan} (${nguoiDuyet})</span></div>`
        )
        .join('');
      return nguoiDuyetStrings;
    })
    .join('');
};
  return (
    <Table striped bordered hover responsive className="mt-3 shadow-sm ">
      <thead className="bg-primary text-white">
        <tr>
          <th>Phòng ban</th>
          <th>Đăng ký</th>
          <th>Chờ duyệt</th>
          <th>Đã duyệt</th>
          <th>Đã ăn</th>
          <th>Chưa ăn</th>
          <th>Ghi chú</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(data.phongBans).map(([phongBan, stats], index) => (
          <tr key={index}>
            <td >{phongBan}</td>
            <td >{stats.dangKy}</td>
            <td style={{color:'red'}}>{stats.choDuyet}</td>
            <td >{stats.daDuyet}</td>
            <td >{stats.daAn}</td>
            <td >{stats.chuaAn}</td>
            <td dangerouslySetInnerHTML={{ __html: createGhiChu(stats.choDuyetGroups) }}></td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
  const renderSummaryCards = (data) => {
  const totals = Object.values(data.phongBans).reduce((acc, stats) => {
    acc.dangKy += stats.dangKy;
    acc.choDuyet += stats.choDuyet;
    acc.daDuyet += stats.daDuyet;
    acc.daAn += stats.daAn;
    acc.chuaAn += stats.chuaAn;
    return acc;
  }, { dangKy: 0, choDuyet: 0, daDuyet: 0, daAn: 0, chuaAn: 0 });

    return (
      <Row className="mb-4">
        <Col md>
          <Card className="text-center h-100 shadow-sm hover-card">
            <Card.Body>
              <FaUsers className="mb-3" size={30} color="#007bff" />
              <Card.Title>Đăng ký</Card.Title>
              <h2>{data.total}</h2>
            </Card.Body>
          </Card>
        </Col>
        <Col md>
          <Card className="text-center h-100 shadow-sm hover-card">
            <Card.Body>
              <FaCalendarAlt className="mb-3" size={30} color="#ffc107" />
              <Card.Title>Chờ duyệt</Card.Title>
              <h2>{totals.choDuyet}</h2>
            </Card.Body>
          </Card>
        </Col>
        <Col md>
          <Card className="text-center h-100 shadow-sm hover-card">
            <Card.Body>
              <FaClipboardCheck className="mb-3" size={30} color="#28a745" />
              <Card.Title>Đã duyệt</Card.Title>
              <h2>{totals.daDuyet}</h2>
            </Card.Body>
          </Card>
        </Col>
        <Col md>
          <Card className="text-center h-100 shadow-sm hover-card">
            <Card.Body>
              <MdFastfood className="mb-3" size={30} color="#c85c10" />
              <Card.Title>Chưa ăn</Card.Title>
              <h2>{totals.chuaAn}</h2>
            </Card.Body>
          </Card>
        </Col>
        <Col md>
          <Card className="text-center h-100 shadow-sm hover-card">
            <Card.Body>
              <FaUtensils className="mb-3" size={30} color="#dc3545" />
              <Card.Title>Đã ăn</Card.Title>
              <h2>{totals.daAn}</h2>
            </Card.Body>
          </Card>
        </Col>
        
      </Row>
    );
  };

  const handlePrevDay = () => {
    const prevDate = moment(activeDate).subtract(1, 'day').format('YYYY-MM-DD');
    if (overtimeData[prevDate]) {
      setActiveDate(prevDate);
    }
  };

  const handleNextDay = () => {
    const nextDate = moment(activeDate).add(1, 'day').format('YYYY-MM-DD');
    if (overtimeData[nextDate]) {
      setActiveDate(nextDate);
    }
  };

    return (
    <>
    <NavTabs/>
    <Container fluid className="py-4 bg-light">
       
      <h1 className="mb-4 text-center">Tổng quan ăn ca</h1>
      <Row className="mb-4 justify-content-center">
        <Col md={6}>
          <DateRangePicker
            initialSettings={{
              startDate: dateRange.startDate.toDate(),
              endDate: dateRange.endDate.toDate(),
              ranges: {
                'Hôm nay': [moment(), moment()],
                'Hôm qua': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                '7 ngày qua': [moment().subtract(6, 'days'), moment()],
                'Tháng này': [moment().startOf('month'), moment().endOf('month')],
                'Tháng trước': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
              }
            }}
            onApply={handleDateRangeChange}
          >
            <div className="input-group date-range-picker">
              <input 
                type="text" 
                className="form-control" 
                value={`${dateRange.startDate.format('DD/MM/YYYY')} - ${dateRange.endDate.format('DD/MM/YYYY')}`}
                readOnly 
              />
              <div className="input-group-append">
                <span className="input-group-text bg-primary text-white">
                  <FaCalendarAlt />
                </span>
              </div>
            </div>
          </DateRangePicker>
        </Col>
      </Row>
      
      <div className="d-flex justify-content-between align-items-center mb-3">
        <Button variant="outline-primary" onClick={handlePrevDay}>
          <FaChevronLeft /> Ngày trước
        </Button>
        <h3 className="m-0">{moment(activeDate).format('DD/MM/YYYY')}</h3>
        <Button variant="outline-primary" onClick={handleNextDay}>
          Ngày sau <FaChevronRight />
        </Button>
      </div>

      {overtimeData[activeDate] && (
        <>
          {renderSummaryCards(overtimeData[activeDate])}
          {renderTable(overtimeData[activeDate])}
        </>
      )}
    </Container>
    </>
  );
};

export default OvertimeGeneral;